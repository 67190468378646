/* eslint-disable import/no-anonymous-default-export */
export default {
    super_admin: [
        'all'
    ],
    super_admin_child: [
        'all'
    ],
    super_admin_child_child: [
        'all'
    ],
    it_admin: [
        'Dashboard',
        'User Configurations',
        'Compliance',
        'System Configuration',
        'Reports',
        'Affiliates',
        'Operations',
    ],
    it_admin_child: [
        'DashboardAll',
        'User ConfigurationsAll',
        'ComplianceAll',
        'System ConfigurationAll',
        'ReportsAll',
        'AffiliatesAll',
        'Trade - Current Positions',
        'User Fiat History',
    ],
    it_admin_child_child: [
        'all'
    ],
    compliance_user: [
        'Dashboard',
        'Operations',
        'Compliance',
        'Reports',
        'User Configurations',
        'System Configuration',
        'Affiliates',
        'Auto Recon',
    ],
    compliance_user_child: [
        'DashboardAll',
        'User Fiat History',
        'User Fiat Refunds',
        'Trade - Current Positions',
        'Compliance',
        'ComplianceAll',
        'Manage Businesses',
        'KYC',
        'EDD',
        'Bank Verification',
        'Compliance Report',
        'KYT Rejected',
        'B2B',
        'Over60 Countdown',
        'Reports',
        'Deposit Cash',
        'Deposit Crypto',
        'Transaction - Coinpass',
        'Transaction - Orders',
        'Users',
        'Login - User Session',
        'Manage User Deposit Wallet Addresses',
        'Manage User Withdraw Wallet Addresses',
        'Compliance Metrics',
        'Withdraw Crypto',
        'Withdraw Cash',
        'Web Control',
        'Duplicate Wallet Addresses',
        'AffiliatesAll',
        'Application Metrics',
    ],
    compliance_user_child_child: [
        'Manage Deleted Users',
        'Manage Archived Users',
        'Wallet Lookup',
    ],
    ops_manager_user: [
        'Dashboard',
        'Operations',
        'Compliance',
        'Reports',
        'Affiliates',
        'System Configuration',
        'User Configurations',
        'Auto Recon',
        'Liquidity',
    ],
    ops_manager_user_child: [
        'DashboardAll',
        'User Fiat History',
        'User Fiat Refunds',
        'Trade - Current Positions',
        'Compliance',
        'Manage Businesses',
        'KYC',
        'EDD',
        'Bank Verification',
        'Reports',
        'Withdraw Crypto',
        'Deposit Crypto',
        'Transaction - Coinpass',
        'Transaction - Orders',
        'AffiliatesAll',
        'Login - User Session',
        'Spread Groups',
        'Manage User Withdraw Wallet Addresses',
        'Refund - Withdraw Crypto',
        'Refund - Withdraw Cash',
        'Trade Analysis',
        'Platform Metrics',
        'Auto Sweeping',
    ],
    ops_manager_user_child_child: [],
    ops_analyst_user: [
        'Dashboard',
        'Operations',
        'Compliance',
        'Reports',
        'Affiliates',
        'User Configurations',
        'System Configuration',
        'Auto Recon',
    ],
    ops_analyst_user_child: [
        'DashboardAll',
        'User Fiat History',
        'User Fiat Refunds',
        'Trade - Current Positions',
        'Compliance',
        'Manage Businesses',
        'KYC',
        'EDD',
        'Bank Verification',
        'Withdraw Crypto',
        'Deposit Crypto',
        'Transaction - Coinpass',
        'Transaction - Orders',
        'AffiliatesAll',
        'Login - User Session',
        'Spread Groups',
        'Manage User Withdraw Wallet Addresses',
        'Refund - Withdraw Crypto',
        'Refund - Withdraw Cash',
        'Pending - Withdraw Cash',
        'Trade Analysis',
        'Platform Metrics',
    ],
    ops_analyst_user_child_child: [],
    service_desk_user: [
        'Dashboard',
        'User Configurations',
        'Operations',
        'Compliance',
        'Reports',
        'System Configuration',
        'Affiliates',
        'Auto Recon',
    ],
    service_desk_user_child: [
        'DashboardAll',
        'Users',
        'Wallet Address Generate',
        'Login - User Session',
        'Register - User Session',
        'Reset - User Session',
        'Auto Trade',
        'User Fiat History',
        'Trade - Current Positions',
        'Client Valuation Statement',
        'KYC',
        'Bank Verification',
        'Transaction - Coinpass',
        'Transaction - Orders',
        'Spread Groups',
        'Web Control',
        'Manage User Withdraw Wallet Addresses',
        'Manage User Deposit Wallet Addresses',
        'Manage User IP Whitelisting',
        'AffiliatesAll',
        'Trade Analysis',
        'Failed Stripe Deposits',
    ],
    service_desk_user_child_child: [
        'all',
    ],
    oanda_user: [
        'Dashboard',
        'System Configuration',
        'Auto Recon',
    ],
    oanda_user_child: [
        'Dashboard',
        'Spread Groups',
        'Application Metrics',
        'Performance Metrics',
        'B2B vs B2C',
        'Oanda Reporting (B2B)',
    ],
    oanda_user_child_child: [],
    service_desk_manager: [
        'Dashboard',
        'User Configurations',
        'Operations',
        'Compliance',
        'Reports',
        'System Configuration',
        'Affiliates',
        'Auto Recon',
    ],
    service_desk_manager_child: [
        'DashboardAll',
        'Users',
        'Wallet Address Generate',
        'Login - User Session',
        'Register - User Session',
        'Reset - User Session',
        'Auto Trade',
        'User Fiat History',
        'Trade - Current Positions',
        'Client Valuation Statement',
        'KYC',
        'Bank Verification',
        'Reports',
        'Transaction - Coinpass',
        'Transaction - Orders',
        'Spread Groups',
        'Web Control',
        'Manage User Withdraw Wallet Addresses',
        'Manage User Deposit Wallet Addresses',
        'Manage User IP Whitelisting',
        'AffiliatesAll',
        'Trade Analysis',
        'Failed Stripe Deposits'
    ],
    service_desk_manager_child_child: [
        "all"
    ],
    B2B_reporting: [
        'Dashboard',
        'Auto Recon',
    ],
    B2B_reporting_child: [
        'Dashboard',
        'Oanda Reporting (B2B)',
    ],
    B2B_reporting_child_child: [],
}
